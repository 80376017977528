import keys from 'constants/keys';

import jwt from 'jsonwebtoken';

export const SET = (name, value) => {
  localStorage.setItem(name, jwt.sign(value, keys.hashKey));
};

export const GET = (name) => {
  const token = localStorage.getItem(name);
  try {
    if (token) return jwt.verify(token, keys.hashKey);
    return {};
  } catch (error) {
    return {};
  }
};

export const REMOVE = (name) => {
  return localStorage.removeItem(name);
};
