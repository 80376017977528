/* eslint-disable import/no-anonymous-default-export */
const dev = {
  // URL: 'http://localhost:3001/',
  URL: 'https://api.advogadofacilapp.com/',
};

const prod = {
  URL: 'https://api.advogadofacilapp.com/',
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

export default {
  ...config,
};
