import {createActions, createReducer} from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: BussinessTypes,
  Creators: BussinessActions,
} = createActions({
  listBussiness: ['params'],
  listBussinessSuccess: ['payload'],
  getBussiness: ['params'],
  getBussinessSuccess: ['payload'],
  getBussinessById: ['payload'],
  getBussinessByIdSuccess: ['payload'],
  deleteBussinessById: ['payload'],
  deleteBussinessByIdSuccess: ['payload'],
  activateBussiness: ['payload', 'callback'],
  activateBussinessSuccess: [],
  updateBussiness: ['payload', 'callback'],
  updateBussinessSuccess: [],
  bussinessRequestFailure: ['error'],
});

const INITIAL_STATE = {
  data: null,
  listBussiness: {
    items: [],
    meta: {
      totalPages: 0,
      itemsPerPage: 10,
      currentPage: 1,
    },
  },
  error: null,
  bussinessRequests: {
    [BussinessTypes.LIST_BUSSINESS]: REQUEST_NOT_STARTED,
    [BussinessTypes.ACTIVATE_BUSSINESS]: REQUEST_NOT_STARTED,
    [BussinessTypes.UPDATE_BUSSINESS]: REQUEST_NOT_STARTED,
    [BussinessTypes.GET_BUSSINESS_BY_ID]: REQUEST_NOT_STARTED,
    [BussinessTypes.DELETE_BUSSINESS_BY_ID]: REQUEST_NOT_STARTED,
  },
};

const listBussiness = (state) => ({
  ...state,
  bussinessRequests: {
    ...INITIAL_STATE.bussinessRequests,
    [BussinessTypes.LIST_BUSSINESS]: REQUEST_PENDING,
  },
});

const listBussinessSuccess = (state, {payload}) => ({
  ...state,
  listBussiness: payload,
  bussinessRequests: {
    ...INITIAL_STATE.bussinessRequests,
    [BussinessTypes.LIST_BUSSINESS]: REQUEST_RESOLVED,
  },
});

const getBussiness = (state) => ({
  ...state,
  bussinessRequests: {
    ...INITIAL_STATE.bussinessRequests,
    [BussinessTypes.GET_BUSSINESS]: REQUEST_PENDING,
  },
});

const getBussinessSuccess = (state, {payload}) => ({
  ...state,
  userData: payload,
  bussinessRequests: {
    ...INITIAL_STATE.bussinessRequests,
    [BussinessTypes.GET_BUSSINESS]: REQUEST_RESOLVED,
  },
});

const getBussinessById = (state) => ({
  ...state,
  data: null,
  bussinessRequests: {
    ...INITIAL_STATE.bussinessRequests,
    [BussinessTypes.GET_BUSSINESS_BY_ID]: REQUEST_PENDING,
  },
});

const getBussinessByIdSuccess = (state, {payload}) => ({
  ...state,
  data: payload,
  bussinessRequests: {
    ...INITIAL_STATE.bussinessRequests,
    [BussinessTypes.GET_BUSSINESS]: REQUEST_RESOLVED,
  },
});

const deleteBussinessById = (state) => ({
  ...state,
  bussinessRequests: {
    ...INITIAL_STATE.bussinessRequests,
    [BussinessTypes.DELETE_BUSSINESS_BY_ID]: REQUEST_PENDING,
  },
});

const deleteBussinessByIdSuccess = (state) => ({
  ...state,
  data: null,
  bussinessRequests: {
    ...INITIAL_STATE.bussinessRequests,
    [BussinessTypes.DELETE_BUSSINESS_BY_ID]: REQUEST_RESOLVED,
  },
});

const activateBussiness = (state) => ({
  ...state,
  bussinessRequests: {
    ...INITIAL_STATE.bussinessRequests,
    [BussinessTypes.ACTIVATE_BUSSINESS]: REQUEST_PENDING,
  },
});

const activateBussinessSuccess = (state) => ({
  ...state,
  bussinessRequests: {
    ...INITIAL_STATE.bussinessRequests,
    [BussinessTypes.ACTIVATE_BUSSINESS]: REQUEST_RESOLVED,
  },
});

const updateBussiness = (state) => ({
  ...state,
  bussinessRequests: {
    ...INITIAL_STATE.bussinessRequests,
    [BussinessTypes.UPDATE_BUSSINESS]: REQUEST_PENDING,
  },
});

const updateBussinessSuccess = (state) => ({
  ...state,
  bussinessRequests: {
    ...INITIAL_STATE.bussinessRequests,
    [BussinessTypes.UPDATE_BUSSINESS]: REQUEST_RESOLVED,
  },
});

const bussinessRequestFailure = (state, {error: {response}}) => ({
  ...state,
  error: response,
  bussinessRequests: {
    ...state.bussinessRequests,
    [BussinessTypes.GET_BUSSINESS]: REQUEST_REJECTED,
    [BussinessTypes.LIST_BUSSINESS]: REQUEST_REJECTED,
    [BussinessTypes.UPDATE_BUSSINESS]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [BussinessTypes.LIST_BUSSINESS]: listBussiness,
  [BussinessTypes.LIST_BUSSINESS_SUCCESS]: listBussinessSuccess,
  [BussinessTypes.GET_BUSSINESS]: getBussiness,
  [BussinessTypes.GET_BUSSINESS_SUCCESS]: getBussinessSuccess,
  [BussinessTypes.GET_BUSSINESS_BY_ID]: getBussinessById,
  [BussinessTypes.GET_BUSSINESS_BY_ID_SUCCESS]: getBussinessByIdSuccess,
  [BussinessTypes.DELETE_BUSSINESS_BY_ID]: deleteBussinessById,
  [BussinessTypes.DELETE_BUSSINESS_BY_ID_SUCCESS]: deleteBussinessByIdSuccess,
  [BussinessTypes.ACTIVATE_BUSSINESS]: activateBussiness,
  [BussinessTypes.ACTIVATE_BUSSINESS_SUCCESS]: activateBussinessSuccess,
  [BussinessTypes.UPDATE_BUSSINESS]: updateBussiness,
  [BussinessTypes.UPDATE_BUSSINESS_SUCCESS]: updateBussinessSuccess,
  [BussinessTypes.BUSSINESS_REQUEST_FAILURE]: bussinessRequestFailure,
});
