import {createActions, createReducer} from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: DashboardTypes,
  Creators: DashboardActions,
} = createActions({
  getDashboards: ['payload'],
  getDashboardsSuccess: ['payload'],
  dashboardRequestSuccess: ['originalType'],
  dashboardRequestFailure: ['error', 'originalType'],
});

const INITIAL_STATE = {
  data: [],
  resource: null,
  error: null,
  dashboardRequests: {
    [DashboardTypes.GET_DASHBOARDS]: REQUEST_NOT_STARTED,
  },
};

const getDashboards = (state) => ({
  ...state,
  dashboardRequests: {
    ...INITIAL_STATE.dashboardRequests,
    [DashboardTypes.GET_DASHBOARDS]: REQUEST_PENDING,
  },
});

const getDashboardsSuccess = (state, {payload}) => ({
  ...state,
  data: payload,
  dashboardRequests: {
    ...INITIAL_STATE.dashboardRequests,
    [DashboardTypes.GET_DASHBOARDS]: REQUEST_RESOLVED,
  },
});

const dashboardRequestSuccess = (state, {originalType}) => ({
  ...state,
  dashboardRequests: {
    ...state.dashboardRequests,
    [originalType]: REQUEST_RESOLVED,
  },
});

const dashboardRequestFailure = (state, {error: {response}, originalType}) => ({
  ...state,
  error: response,
  dashboardRequests: {
    ...state.dashboardRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [DashboardTypes.GET_DASHBOARDS]: getDashboards,
  [DashboardTypes.GET_DASHBOARDS_SUCCESS]: getDashboardsSuccess,
  [DashboardTypes.DASHBOARD_REQUEST_SUCCESS]: dashboardRequestSuccess,
  [DashboardTypes.DASHBOARD_REQUEST_FAILURE]: dashboardRequestFailure,
});
