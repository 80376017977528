import {createActions, createReducer} from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {Types: AuthTypes, Creators: AuthActions} = createActions({
  signIn: ['payload'],
  forgotPassword: ['payload'],
  signOut: [],
  authRequestSuccess: ['originalType'],
  authRequestFailure: ['error', 'originalType'],
});

const INITIAL_STATE = {
  authorization: null,
  refreshToken: null,
  error: null,
  authRequests: {
    [AuthTypes.SIGN_IN]: REQUEST_NOT_STARTED,
    [AuthTypes.FORGOT_PASSWORD]: REQUEST_NOT_STARTED,
  },
};
const forgotPassword = (state) => ({
  ...state,
  authRequests: {
    ...INITIAL_STATE.authRequests,
    [AuthTypes.FORGOT_PASSWORD]: REQUEST_PENDING,
  },
});
const signIn = (state) => ({
  ...state,
  authRequests: {
    ...INITIAL_STATE.authRequests,
    [AuthTypes.SIGN_IN]: REQUEST_PENDING,
  },
});
const signOut = (state) => ({
  ...state,
  authRequests: {
    ...INITIAL_STATE.authRequests,
  },
});

const authRequestSuccess = (state, {originalType}) => ({
  ...state,
  authRequests: {
    ...state.authRequests,
    [originalType]: REQUEST_RESOLVED,
  },
});

const authRequestFailure = (state, {error, originalType}) => ({
  ...state,
  error: error,
  authRequests: {
    ...state.authRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [AuthTypes.SIGN_IN]: signIn,
  [AuthTypes.FORGOT_PASSWORD]: forgotPassword,
  [AuthTypes.SIGN_OUT]: signOut,
  [AuthTypes.AUTH_REQUEST_SUCCESS]: authRequestSuccess,
  [AuthTypes.AUTH_REQUEST_FAILURE]: authRequestFailure,
});
