import ENV from 'constants/env';

const base = ENV.URL;

export const basePhoto = 'https://dexp70qwz39bv.cloudfront.net/';

export const basePath = `${base}/`;
export const baseMock = 'https://run.mocky.io/v3/';
export const SIGN_IN = `admin/login`;
export const FORGOT_PASSWORD = `admin/forgot-password`;
export const RESOURCE_PUBLICITIES = 'publicities';
export const RESOURCE_USERS = 'users';
export const RESOURCE_DASHBOARDS = 'dashboard';
export const RESOURCE_BUSSINESS = 'bussiness';
export const RESOURCE_BUSSINESS_ACTIVATE = (id) => `bussiness/activate/${id}`;
export const RESOURCE_BUSSINESS_UPDATE = (id) => `bussiness/update/${id}`;
export const RESOURCE_ADMIN = 'admin';
export const DELETE_ADMIN = (id) => `admin/${id}`;
export const RESOURCE_ADMIN_ACTIVATE = (id) => `admin/activate/${id}`;
export const RESOURCE_RATING = 'ratings';
export const RESOURCE_PROGRAMMINGS = 'programmings';
export const DELETE_PUBLICITY = (id) => `publicities/${id}`;
export const GET_BANNERS = 'banners';
export const DELETE_BANNER = (id) => `banners/${id}`;
export const RESOURCE_RATING_ACTIVATE = (id) => `ratings/approve/${id}`;
export const DELETE_PROGRAMMING = (id) => `programmings/${id}`;
