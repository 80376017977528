import {createGlobalStyle} from 'styled-components';
import tokens from 'assets/styles/tokens';

import ProximaNovaBold from 'assets/styles/fonts/Proxima-Nova-Bold.otf';
import ProximaNovaMedium from 'assets/styles/fonts/Proxima-Nova-Regular.otf';
import ProximaNovaRegular from 'assets/styles/fonts/Proxima-Nova-Regular.otf';
import ProximaNovaSemiBold from 'assets/styles/fonts/Proxima-Nova-Semibold.otf';

const GlobalStyle = createGlobalStyle` 
@font-face {
  font-family: 'ProximaNovaRegular';
  src: url(${ProximaNovaRegular}) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'ProximaNovaMedium';
  src: url(${ProximaNovaMedium}) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'ProximaNovaSemiBold';
  src: url(${ProximaNovaSemiBold}) format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'ProximaNovaBold';
  src: url(${ProximaNovaBold}) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
  * {    
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  body {
    margin:0;
    font-family: 'ProximaNovaRegular', sans-serif;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background: ${tokens.colors.background};
  }
`;
export default GlobalStyle;
