import {createActions, createReducer} from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {Types: AdminTypes, Creators: AdminActions} = createActions({
  listAdmin: ['params'],
  listAdminSuccess: ['payload'],
  getAdmin: ['params'],
  getAdminSuccess: ['payload'],
  activateAdmin: ['payload', 'callback'],
  activateAdminSuccess: [],
  createAdmin: ['payload', 'callback'],
  createAdminSuccess: [],
  updateAdmin: ['payload', 'callback'],
  updateAdminSuccess: [],
  deleteAdmin: ['payload', 'callback'],
  deleteAdminSuccess: [],
  adminRequestFailure: ['error'],
});

const INITIAL_STATE = {
  listAdmin: {
    items: [],
    meta: {
      totalPages: 0,
      itemsPerPage: 10,
      currentPage: 1,
    },
  },
  error: null,
  adminRequests: {
    [AdminTypes.LIST_ADMIN]: REQUEST_NOT_STARTED,
  },
};

const listAdmin = (state) => ({
  ...state,
  adminRequests: {
    ...INITIAL_STATE.adminRequests,
    [AdminTypes.LIST_ADMIN]: REQUEST_PENDING,
  },
});
const listAdminSuccess = (state, {payload}) => ({
  ...state,
  listAdmin: payload,
  adminRequests: {
    ...INITIAL_STATE.adminRequests,
    [AdminTypes.LIST_ADMIN]: REQUEST_RESOLVED,
  },
});
const getAdmin = (state) => ({
  ...state,
  adminRequests: {
    ...INITIAL_STATE.adminRequests,
    [AdminTypes.GET_ADMIN]: REQUEST_PENDING,
  },
});
const getAdminSuccess = (state, {payload}) => ({
  ...state,
  userData: payload,
  adminRequests: {
    ...INITIAL_STATE.adminRequests,
    [AdminTypes.GET_ADMIN]: REQUEST_RESOLVED,
  },
});
const activateAdmin = (state) => ({
  ...state,
  adminRequests: {
    ...INITIAL_STATE.adminRequests,
    [AdminTypes.ACTIVATE_ADMIN]: REQUEST_PENDING,
  },
});
const activateAdminSuccess = (state) => ({
  ...state,
  adminRequests: {
    ...INITIAL_STATE.adminRequests,
    [AdminTypes.ACTIVATE_ADMIN]: REQUEST_RESOLVED,
  },
});
const createAdmin = (state) => ({
  ...state,
  adminRequests: {
    ...INITIAL_STATE.adminRequests,
    [AdminTypes.CREATE_ADMIN]: REQUEST_PENDING,
  },
});
const createAdminSuccess = (state) => ({
  ...state,
  adminRequests: {
    ...INITIAL_STATE.adminRequests,
    [AdminTypes.CREATE_ADMIN]: REQUEST_RESOLVED,
  },
});
const updateAdmin = (state) => ({
  ...state,
  adminRequests: {
    ...INITIAL_STATE.adminRequests,
    [AdminTypes.UPDATE_ADMIN]: REQUEST_PENDING,
  },
});
const updateAdminSuccess = (state) => ({
  ...state,
  adminRequests: {
    ...INITIAL_STATE.adminRequests,
    [AdminTypes.UPDATE_ADMIN]: REQUEST_RESOLVED,
  },
});
const deleteAdmin = (state) => ({
  ...state,
  adminRequests: {
    ...INITIAL_STATE.adminRequests,
    [AdminTypes.UPDATE_ADMIN]: REQUEST_PENDING,
  },
});
const deleteAdminSuccess = (state) => ({
  ...state,
  adminRequests: {
    ...INITIAL_STATE.adminRequests,
    [AdminTypes.UPDATE_ADMIN]: REQUEST_RESOLVED,
  },
});
const adminRequestFailure = (state, {error: {response}}) => ({
  ...state,
  error: response,
  adminRequests: {
    ...state.adminRequests,
    [AdminTypes.GET_ADMIN]: REQUEST_REJECTED,
    [AdminTypes.CREATE_ADMIN]: REQUEST_REJECTED,
    [AdminTypes.UPDATE_ADMIN]: REQUEST_REJECTED,
    [AdminTypes.DELETE_ADMIN]: REQUEST_REJECTED,
    [AdminTypes.LIST_ADMIN]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [AdminTypes.LIST_ADMIN]: listAdmin,
  [AdminTypes.LIST_ADMIN_SUCCESS]: listAdminSuccess,
  [AdminTypes.GET_ADMIN]: getAdmin,
  [AdminTypes.GET_ADMIN_SUCCESS]: getAdminSuccess,
  [AdminTypes.ACTIVATE_ADMIN]: activateAdmin,
  [AdminTypes.ACTIVATE_ADMIN_SUCCESS]: createAdminSuccess,
  [AdminTypes.CREATE_ADMIN]: createAdmin,
  [AdminTypes.CREATE_ADMIN_SUCCESS]: activateAdminSuccess,
  [AdminTypes.UPDATE_ADMIN]: updateAdmin,
  [AdminTypes.UPDATE_ADMIN_SUCCESS]: updateAdminSuccess,
  [AdminTypes.DELETE_ADMIN]: deleteAdmin,
  [AdminTypes.DELETE_ADMIN_SUCCESS]: deleteAdminSuccess,
  [AdminTypes.ADMIN_REQUEST_FAILURE]: adminRequestFailure,
});
