import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import auth from './auth/auth.ducks';
import user from './user/user.ducks';
import bussiness from './bussiness/bussiness.ducks';
import admin from './admin/admin.ducks';
import rating from './rating/rating.ducks';
import message from './message/message.ducks';
import dashboard from './dashboard/dashboard.ducks';

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    admin,
    user,
    bussiness,
    rating,
    message,
    dashboard,
  });

const Reducers = (history) => appReducer(history);

export default Reducers;
