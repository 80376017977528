import {createActions, createReducer} from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {Types: RatingTypes, Creators: RatingActions} = createActions({
  listRating: ['params'],
  listRatingSuccess: ['payload'],
  getRating: ['params'],
  getRatingSuccess: ['payload'],
  activateRating: ['payload', 'callback'],
  activateRatingSuccess: [],
  ratingRequestFailure: ['error'],
});

const INITIAL_STATE = {
  listRating: {
    items: [],
    meta: {
      totalPages: 0,
      itemsPerPage: 10,
      currentPage: 1,
    },
  },
  error: null,
  ratingRequests: {
    [RatingTypes.LIST_RATING]: REQUEST_NOT_STARTED,
  },
};

const listRating = (state) => ({
  ...state,
  ratingRequests: {
    ...INITIAL_STATE.ratingRequests,
    [RatingTypes.LIST_RATING]: REQUEST_PENDING,
  },
});
const listRatingSuccess = (state, {payload}) => ({
  ...state,
  listRating: payload,
  ratingRequests: {
    ...INITIAL_STATE.ratingRequests,
    [RatingTypes.LIST_RATING]: REQUEST_RESOLVED,
  },
});
const getRating = (state) => ({
  ...state,
  ratingRequests: {
    ...INITIAL_STATE.ratingRequests,
    [RatingTypes.GET_RATING]: REQUEST_PENDING,
  },
});
const getRatingSuccess = (state, {payload}) => ({
  ...state,
  userData: payload,
  ratingRequests: {
    ...INITIAL_STATE.ratingRequests,
    [RatingTypes.GET_RATING]: REQUEST_RESOLVED,
  },
});
const activateRating = (state) => ({
  ...state,
  ratingRequests: {
    ...INITIAL_STATE.ratingRequests,
    [RatingTypes.ACTIVATE_RATING]: REQUEST_PENDING,
  },
});
const activateRatingSuccess = (state) => ({
  ...state,
  ratingRequests: {
    ...INITIAL_STATE.ratingRequests,
    [RatingTypes.ACTIVATE_RATING]: REQUEST_RESOLVED,
  },
});
const ratingRequestFailure = (state, {error: {response}}) => ({
  ...state,
  error: response,
  ratingRequests: {
    ...state.ratingRequests,
    [RatingTypes.GET_RATING]: REQUEST_REJECTED,
    [RatingTypes.LIST_RATING]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [RatingTypes.LIST_RATING]: listRating,
  [RatingTypes.LIST_RATING_SUCCESS]: listRatingSuccess,
  [RatingTypes.GET_RATING]: getRating,
  [RatingTypes.GET_RATING_SUCCESS]: getRatingSuccess,
  [RatingTypes.ACTIVATE_RATING]: activateRating,
  [RatingTypes.ACTIVATE_RATING_SUCCESS]: activateRatingSuccess,
  [RatingTypes.RATING_REQUEST_FAILURE]: ratingRequestFailure,
});
