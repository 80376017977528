import {createActions, createReducer} from 'reduxsauce';

export const {Types: MessagesTypes, Creators: MessagesActions} = createActions({
  open: ['payload'],
  close: [],
});

const INITIAL_STATE = {
  type: 'info',
  title: '',
  callback: () => {},
  autoClose: true,
  contentText: '',
  open: false,
  modal: false,
};

const open = (state, {payload}) => ({
  ...state,
  ...payload,
  open: true,
});

const close = () => ({
  ...INITIAL_STATE,
});
export default createReducer(INITIAL_STATE, {
  [MessagesTypes.OPEN]: open,
  [MessagesTypes.CLOSE]: close,
});
